import React from 'react'
import Helmet from 'react-helmet'

const SEO = ({title}) => {
  return (
    <Helmet>
      <title>delia drin </title>
      <meta name="google-site-verification" content="Yz9bhZRq_6UKgUDgJs-3BvU32ErZ-qG466yekWtz9BE" />
      <meta name="description" content={title} />
      <script src="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.js" defer={true}/>
      <link rel="stylesheet" type="text/css" href="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.css" />
    </Helmet>
  )
}

export default SEO
