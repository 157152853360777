import React, { useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'


const getDefaultStyle = (duration) => ({
  transition: `opacity ${duration}ms ease-in`,
  width: '100%'
})

const transitionStyles = {
  entering: { opacity: 0.9 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const Fade = ({ children, disabled, duration=400 }) => {
  const [visible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
  }, [])

  return (
    <Transition in={disabled || visible} out timeout={duration}>
      {(state) => (
        <div
          style={{
            ...getDefaultStyle(duration),
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}
export default Fade
